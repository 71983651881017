<script setup lang="ts">
const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);
</script>

<template>
  <nav class="relative bg-white">
    <div class="mx-auto px-4 sm:px-6">
      <div
        class="flex items-center justify-between border-b-2 border-gray-100 py-6 md:justify-start md:space-x-10"
      >
        <div class="flex justify-start space-x-4 md:space-x-0 lg:w-0 lg:flex-1">
          <NuxtLink :to="formatLink(`/`)">
            <span class="sr-only">SN super.natural Online Shop</span>
            <img v-lazy-load 
              class="h-10 w-10 lg:h-12 lg:w-12"
              fetchpriority="high"
              data-src="/logo/sn_logo_schwarz.svg"
              alt="SN logo"
              width="40"
              height="40"
            />
          </NuxtLink>
        </div>
        <AccountMenu />
      </div>
    </div>
  </nav>
</template>
